import React from "react";
import { Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers";
import { URL } from "../Navbar/routes";
import { clearUserData } from "../../redux/actions/userActions";

import "./Header.scoped.scss";

const Navbar: React.FC = (props: any) => {
  const user = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  const goToLogin = () => {
    localStorage?.clear();
    sessionStorage?.clear();
    dispatch(clearUserData());
    props.history.push("/login");
  };

  const gotoProfile = () => {
    props.history.push(URL.DASHBOARD_PROFILE);
  }

  const goToSettings = () => {
    props.history.push(URL.REPORT_SETTINGS);
  };

  return (
    <div className="m-d-block d-header bg-white">
      <div className="header-section d-flex align-items-center justify-content-between px-4 py-3 m-px-3 bg-white">
        <div className="header-menu-logo d-flex">
          <h4>Walkthru Labs</h4>
        </div>

        <div className="d-flex align-items-center">
          <Dropdown className="user-profile">
            <Dropdown.Toggle
              variant="Primary"
              id="dropdown-basic"
              bsPrefix="my-dropdown-toggle p-0"
              className="user-profile-img mr-0"
            >
              <div className="profile-img">
                <img className="h-100 w-100 rounded-circle" src={user.avatar || "/images/userimg.svg"} alt="" />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={gotoProfile}>Profile</Dropdown.Item>
              <Dropdown.Item onClick={goToSettings}>Walkthru Settings</Dropdown.Item>
              <Dropdown.Item onClick={goToLogin}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Navbar);
