import apiPlugin, { ApiResponse } from "./apiPlugin";
import fileUploadPlugin from "./fileUploadPlugin";

// export async function uploadFile(
//     apiData: any
// ): Promise<ApiResponse<any>> {
//     return apiPlugin<any>(
//         "PUT",
//         "media/file-upload",
//         undefined,
//         undefined,
//         apiData
//     );
// }

export async function uploadFile(
    apiData: any
): Promise<ApiResponse<any>> {
    return fileUploadPlugin<any>(
        "POST",
        "upload",
        undefined,
        undefined,
        apiData
    );
}

export async function createFilterTest(
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "POST",
        "filter-testing",
        undefined,
        apiData
    );
}

export async function createAnalyte(
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "POST",
        "library",
        undefined,
        apiData
    );
}

export async function updateFilterTest(
    apiData: any, id: string
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        `filter-testing/${id}`,
        undefined,
        apiData
    );
}

export async function uploadModifiedReport(
    apiData: any, id: string
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        `filter-testing/upload-report/${id}`,
        undefined,
        apiData
    );
}

export async function updateAnalyte(
    apiData: any, id: string
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        `library/${id}`,
        undefined,
        apiData
    );
}

export async function deleteAnalyte(
    id: string
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "DELETE",
        `library/${id}`,
        undefined
    );
}

export async function updateLabTest(
    id: string,
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "PUT",
        `lab-test/${id}`,
        undefined,
        apiData
    );
}

export async function getLabTest(): Promise<ApiResponse<any>> {
    return apiPlugin<any>("GET", `lab-test`);
}

export async function getAnalytes(showMedia: boolean): Promise<ApiResponse<any>> {
    return apiPlugin<any>("GET", `library?showMedia=${showMedia}`);
}

export async function createLabTest(
    apiData: any
): Promise<ApiResponse<any>> {
    return apiPlugin<any>(
        "POST",
        "lab-test",
        undefined,
        apiData
    );
}
