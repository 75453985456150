import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./ForgotPassword.scoped.scss";
import { forgotPasswordApi } from "../../api/UserController";
import { SpinnerLoader } from "../../common/Global.Style";
import { withRouter } from "react-router-dom";
import { ApiResponse } from "../../common/Interface";

const ForgotPassword: React.FC = (props: any) => {
  const [response, setResponse] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userForgotPassword = (value: object) => {
    setIsLoading(true);
    forgotPasswordApi(value)
      .then((response: any) => {
        if (response.responseObj) {
          setResponse({
            isSuccess: true,
            message: response.responseObj.success,
          });
          setTimeout(() => {
            props.history.push("/login");
          }, 2000);
        } else {
          setResponse({ isSuccess: false, message: response.error });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setResponse({ isSuccess: false, message: error.message });
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email ID is required"),
    }),
    onSubmit: (values: object) => {
      userForgotPassword(values);
    },
  });

  return (
    <>
      <div className="forgot-page d-flex">
        <div className="flex-1 forgot-banner">
          <div className="forgot-logo" />
          <div className="d-flex align-items-end forgot-content font-700">
            <div className="text-content">
              <h4 className="fs-60 ls-024 txt-white d-flex flex-column mb-4 lh-72">
                <span className="font-warn-light-1">Walkthru Labs</span>
              </h4>
              <p className="desc-xlarge font-white-85 ls-024 mb-5">
                For a Healthier, Cleaner & Safer Country
              </p>
            </div>
          </div>
        </div>
        <div className=" flex-1 bg-white p-3 forgot-block d-flex flex-column justify-content-center">
          <div className="w-75 mx-auto">
            <h4 className="mb-4 fs-24 font-700 ls-024 lh-normal font-text-1">Forgot Password</h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label className="desc-normal ls-024 font-grey-light-4">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="input-email bg-light border-0 mt-2"
                  placeholder="Type email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
              </div>
              {response && (
                <div
                  className={response.isSuccess ? "text-success" : "text-danger"}
                >
                  {response.message ? response.message : ""}
                </div>
              )}

              <div className="d-flex flex-column align-items-center justify-content-center">
                <button type="submit" className="btn btn-submit mb-3 mr-0">
                  submit
                </button>
                <a href="/login" className="link">
                  Go to login
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isLoading && <SpinnerLoader />}
    </>
  );
};
export default withRouter(ForgotPassword);
