import { SelectableRows } from "mui-datatables";
import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import {
	createLabTest,
	getAnalytes,
	getLabTest, updateLabTest,
} from "../../api/TestController";
import { SpinnerLoader } from "../../common/Global.Style";
import "./LabTest.scss";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { AddEditLabTest } from "./AddLabTest";
import { Dropdown } from "react-bootstrap";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import copy from "copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { TableCell } from "@material-ui/core";

const LabTest = () => {

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [labTestList, setLabTestList] = useState<any>([]);
	const [showDrawer, setShowDrawer] = useState<boolean>(false);
	const [selectedTest, setSelectedTest] = useState<any>({});
	const [analytes, setAnalytesList] = useState<any>([]);
	const [emailContent, setEmailContent] = useState<any>("");

	useEffect(() => {
		getLabTestList();
		getAnalytesList();
	}, []);

	const getLabTestList = async () => {
		setIsLoading(true);
		let response = await getLabTest();

		setIsLoading(false);
		if (response?.responseObj?.data?.length) {
			setLabTestList(response?.responseObj?.data);
		}
	}

	const getAnalytesList = async () => {
		setIsLoading(true);
		let response = await getAnalytes(false);

		setIsLoading(false);
		if (response?.responseObj?.data?.length) {
			setEmailContent(response.responseObj.emailContent || "Please find your Walkthru here.");
			setAnalytesList(response.responseObj.data);
		}
	}

	const onSubmit = async (values: any) => {
		setIsLoading(true);
		let response;
		if (selectedTest._id) {
			response = await updateLabTest(selectedTest._id, {
				...values,
				_id: selectedTest._id,
			});
			setIsLoading(false);
		} else {
			response = await createLabTest(values);
			setIsLoading(false);
		}
		if (response?.error) {
			toast.error(response?.error);
		} else if (response?.responseObj.data) {
			setShowDrawer(false);
			getLabTestList();
			toast.success(response?.responseObj.message);
		}
	};

	const copyLinktoClipBoard = (link: string) => {
		copy(link);
		toast.success('Copied to Clipboard');
	}

	const copyEmailContent = async (id: string) => {
		const test = labTestList.find((l: any) => l._id === id);
		if (!test) {
			return;
		}

		const content =
			`<p>${emailContent}</p>
				<div>
					<a href=${test.walkthruLink}>${test.walkthruLink}</a>
				</div>
				<div>
					<img style="heright: 150px; width:150px" src=${test.thumbnail} />
					<img style="heright: 100px; width:100px; margin-left:10px" src=${test.qrCode} />
				</div>`;
		const blob = new Blob([content], { type: 'text/html' });
		const clipboardItem = new window.ClipboardItem({ 'text/html': blob });
		navigator.clipboard.write([clipboardItem]);
		toast.success('Copied to Clipboard');
	}

	const downloadSampleFile = async () => {
		const response = await fetch('https://icloud-image-uploader.s3.amazonaws.com/analytes/sample.csv');
		const blob = await response.blob();
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = 'sample.csv';
		a.click();
		URL.revokeObjectURL(url);
	}

	const columns = [
		{
			name: "name",
			label: "Name",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "testId",
			label: "Test ID",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "labId",
			label: "Lab ID",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any) => {
					return (value ? <span>{value}</span> : <span>-</span>)
				},
			},
		},
		{
			name: "partnerId",
			label: "Partner ID",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any) => {
					return (value ? <span>{value}</span> : <span>-</span>)
				},
			},
		},
		{
			name: "createdAt",
			label: "Created At",
			options: {
				sort: true,
				filter: false,
				customBodyRender: (value: any) => {
					return (<span>{moment(value).format("lll")}</span>)
				},
			},
		},
		{
			label: "Copy Walkthru",
			name: "walkthruLink",
			options: {
				customHeadRender: () => (
					<TableCell>
						<div className="download-report">Copy Walkthru</div>
					</TableCell>
				),
				customBodyRender: (value: any) => {
					return (
						<FileCopyIcon
							onClick={() => copyLinktoClipBoard(value)}
							className="ml-2"
						/>
					)
				},
			},
		},
		{
			label: "Copy Email",
			name: "_id",
			options: {
				customHeadRender: () => (
					<TableCell>
						<div className="download-report">Copy Email Content</div>
					</TableCell>
				),
				customBodyRender: (value: any) => {
					return (
						<FileCopyIcon
							onClick={() => copyEmailContent(value)}
							className="ml-2"
						/>
					)
				},
			},
		},
		{
			name: "_id",
			label: "Actions",
			options: {
				filter: false,
				customBodyRender: (value: string) => {
					return (
						<Dropdown className="">
							<Dropdown.Toggle
								variant="Primary"
								id="dropdown-basic"
								bsPrefix="dropdown-button"
							>
								<MoreHorizIcon />
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ width: "inherit" }}>
								<Dropdown.Item
									className="simple text-break"
									onClick={() => {
										const selectedData: any = labTestList.find(
											(mapData: any) => mapData._id === value
										);
										setSelectedTest(selectedData);
										setShowDrawer(true);
									}}
								>
									Edit
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					);
				},
			},
		}
	];

	const options = {
		viewColumns: false,
		responsive: "standard",
		selectableRows: "none" as SelectableRows,
		download: true,
		downloadOptions: {
			filename: "Labtest.csv",
		},
		print: false,
	};

	return (
		<div className="px-4 py-3">
			<div className="d-flex justify-content-end mb-4">
				<label onClick={downloadSampleFile} className="mr-3">
					<p className="btn add-device-btn btn-medium btn-primary-outline upload-btn cursor-pointer m-0">
						Download Sample
					</p>
				</label>

				<Button
					onClick={() => {
						setShowDrawer(true);
						setSelectedTest({});
					}
					}
					className="btn font-600 btn-medium mr-0">
					Add New
				</Button>

			</div>

			{isLoading && <SpinnerLoader />}

			<div className="table-web-block">
				<CustomDataTable
					title="Lab Test Reports"
					rows={labTestList}
					columns={columns}
					options={options}
				/>
			</div>

			{
				showDrawer &&
				<AddEditLabTest
					formData={selectedTest}
					isLoading={isLoading}
					onSubmit={onSubmit}
					open={showDrawer}
					onclose={() => setShowDrawer(false)}
					analytes={analytes}
				/>
			}

		</div >)
};

export default LabTest;
