import { SelectableRows } from "mui-datatables";
import CustomDataTable from "../../components/CustomDataTable/CustomDataTable";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AddEditAnalytes } from "./AddEditLibrary";
import { toast } from "react-toastify";
import { createAnalyte, deleteAnalyte, getAnalytes, updateAnalyte } from "../../api/TestController";
import { SpinnerLoader } from "../../common/Global.Style";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import "./Library.scss";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Dropdown } from "react-bootstrap";

const AnalytesList = () => {

	const [showDrawer, setShowDrawer] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [list, setList] = useState<any>([]);
	const [selectedAnalyte, setSelectedAnalyte] = useState<any>({});
	const [showDeleteTest, setShowDeleteTest] = useState<boolean>(false);

	useEffect(() => {
		getAnalytesList();
	}, []);

	const getAnalytesList = async () => {
		setIsLoading(true);
		let response = await getAnalytes(true);
		setIsLoading(false);
		if (response?.responseObj?.data?.length) {
			setList(response.responseObj.data);
		}
	}

	const onDelete = async () => {
		setIsLoading(true);
		let response = await deleteAnalyte(selectedAnalyte?._id);
		setIsLoading(false);
		if (response?.error) {
			toast.error(response?.error);
		} else if (response?.responseObj.message) {
			setShowDeleteTest(false);
			toast.success("Deleted successfully");
			getAnalytesList();
		}
	};

	const onSUbmit = async (values: any) => {
		setIsLoading(true);
		let response;
		if (selectedAnalyte._id) {
			response = await updateAnalyte({
				...values,
				_id: selectedAnalyte._id,
			}, selectedAnalyte._id);
			setIsLoading(false);
		} else {
			response = await createAnalyte(values);
			setIsLoading(false);
		}
		if (response?.error) {
			toast.error(response?.error);
		} else if (response?.responseObj.data) {
			setShowDrawer(false);
			getAnalytesList();
			toast.success(response?.responseObj.message);
		}
	};

	const columns = [
		{
			name: "name",
			label: "Name",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "code",
			label: "Code",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "definition",
			label: "Definition",
			options: {
				filter: false
			},
		},
		{
			name: "_id",
			label: "Actions",
			options: {
				filter: false,
				customBodyRender: (value: string) => {
					return (
						<Dropdown className="">
							<Dropdown.Toggle
								variant="Primary"
								id="dropdown-basic"
								bsPrefix="dropdown-button"
							>
								<MoreHorizIcon />
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ width: "inherit" }}>
								<Dropdown.Item
									className="simple text-break"
									onClick={() => {
										const selectedData: any = list.find(
											(mapData: any) => mapData._id === value
										);
										setSelectedAnalyte(selectedData);
										setShowDrawer(true);
									}}
								>
									Edit
								</Dropdown.Item>

								<Dropdown.Item
									className="simple text-break"
									onClick={() => {
										const selectedData: any = list.find(
											(mapData: any) => mapData._id === value
										);
										setSelectedAnalyte(selectedData);
										setShowDeleteTest(true);
									}}
								>
									Delete
								</Dropdown.Item>

							</Dropdown.Menu>
						</Dropdown>
					);
				},
			},
		}
	];

	const options = {
		download: false,
		print: false,
		viewColumns: false,
		responsive: "standard",
		selectableRows: "none" as SelectableRows,
		rowsPerPage: 100
	};

	return (
		<div className="px-4 pt-4 pb-5">
			<div className="d-flex justify-content-end mb-4">
				<Button onClick={() => {
					setShowDrawer(true);
					setSelectedAnalyte({});
				}
				} className="btn mr-0 btn-medium">
					Add New
				</Button>
			</div>

			{isLoading && <SpinnerLoader />}

			{showDrawer && (
				<AddEditAnalytes
					open={showDrawer}
					onclose={() => setShowDrawer(false)}
					formData={selectedAnalyte}
					onSUbmit={onSUbmit}
					setIsLoading={setIsLoading}
					isLoading={isLoading}
				/>
			)}

			{showDeleteTest && (
				<ConfirmDialog
					dialogTitle="Delete"
					message="Are you sure want to delete the analyte?"
					positiveBtnLabel="Confirm"
					negativeBtnLabel="Cancel"
					onPositiveBtnClicked={onDelete}
					onNegativeBtnClicked={() => setShowDeleteTest(false)}
					onCloseIconClicked={() => setShowDeleteTest(false)}
				/>
			)}
			<div className="microb-tab-section">
				<CustomDataTable
					title="Analytes"
					rows={list}
					columns={columns}
					options={options}
				/>
			</div>
		</div>)
};

export default AnalytesList;
