import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory, withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { loginApi } from "../../api/UserController";
import { SpinnerLoader } from "../../common/Global.Style";
import { setToken, setUser } from "../../redux/actions/userActions";
import { URL } from "../../components/Navbar/routes";
import { RootState } from "../../redux/reducers";
import Iqilogo from '../../images/labs-logo.png';

import "./Login.scoped.scss";

const Login: React.FC = () => {
	const [errorText, setErrorText] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const history = useHistory();
	const dispatch = useDispatch();

	const userData: any = useSelector((state: RootState) => state.user);

	useEffect(() => {
		if (userData?.token) {
			history.push(URL.DASHBOARD);
			setIsLoading(false);
		} else {
			history.push(URL.LOGIN);
			setIsLoading(false);
		}
	}, []);

	const userLogin = async (value: object) => {
		setIsLoading(true);
		const response: any = await loginApi(value);
		if (response.responseObj?.data) {
			setErrorText("");
			dispatch(setToken(response.responseObj.data?.accessToken));
			dispatch(setUser(response.responseObj.data));
			localStorage.setItem("userId", response.responseObj.data?._id);
			history.push(URL.DASHBOARD);
		} else {
			setErrorText(response.error);
		}
		setIsLoading(false);
	};

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email("Invalid email address")
				.required("Email is required"),
			password: Yup.string().required("Password is required"),
		}),
		onSubmit: (values) => {
			userLogin(values);
		},
	});

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};

	return (
		<>
			<div className="login-page d-flex">
				<div className="flex-1 login-banner">
					<div className="login-logo" />
					<div className="d-flex align-items-end login-content font-700">
						<div className="text-content">
							<h4 className="fs-60 ls-024 txt-white d-flex flex-column mb-4 lh-72">
								<span className="font-warn-light-1">Walkthru Labs</span>
							</h4>
							<p className="desc-xlarge font-white-85 ls-024">
								For a Healthier, Cleaner & Safer Country
							</p>
						</div>
					</div>
				</div>
				<div className="login-content flex-1 bg-white">
					<div className="p-3 login-block">
						<div className="d-flex align-items-center justify-content-end py-2 px-2 sticky-top-0 bg-white login-mobile-nosign">
							<p className="mb-0 mr-3">Don't have an account?</p>
							<a href="#"
								onClick={() => history.push("/sign-up")}
								className="btn btn-outline-primary align-self-center mr-2">
								Sign Up
							</a>
						</div>
						<div className="login-form d-flex flex-column login-main align-items-center">
							<div className="form-section w-100 py-3 mx-auto">
								<div className="w-100 logo-mobile pb-4 mb-2">
									<img className="" src={Iqilogo} alt="" />
									<p className="fs-14 font-400 font-grey-light-4">
										For a Healthier, Cleaner & Safer Country
									</p>
								</div>
								<h4 className="mb-2 fs-24 font-700 ls-024 lh-normal font-text-1">
									Log In
								</h4>

								<div className="mb-2">
									<form onSubmit={formik.handleSubmit}>
										<div className="mb-4 form-group">
											<label className="desc-normal ls-024 font-grey-light-4">
												Email
											</label>
											<input
												type="email"
												id="email"
												name="email"
												className="email bg-white profileinput border-radius-4 mt-2"
												placeholder="Enter your email address"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.email}
											/>
											{formik.touched.email && formik.errors.email ? (
												<div className="desc-medium text-danger mt-1">
													{formik.errors.email}
												</div>
											) : null}
										</div>
										<div className="mb-2 form-group">
											<label className="desc-normal ls-024 font-grey-light-4">
												Password
											</label>
											<div className="password">
												<input
													type={showPassword ? "text" : "password"}
													id="password"
													name="password"
													className="bg-white profileinput border-radius-4"
													placeholder="Enter your password"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.password}
												/>
												<IconButton
													className="icon"
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</div>
											{formik.touched.password && formik.errors.password ? (
												<div className="desc-medium text-danger mt-1">
													{formik.errors.password}
												</div>
											) : null}
										</div>
										<p className="desc-medium text-danger ml-2 pl-1 mt-1 mb-0">
											{" "}
											{errorText ? errorText : ""}{" "}
										</p>
										<p className="mb-10 text-left">
											<Link
												to="/forgot-password"
												className="mt-2 desc-normal font-dark-blue font-500 fs-14 ls-024 lh-16"
											>
												Forgot Password?
											</Link>
										</p>
										<button
											type="submit"
											className="btn  w-100 mb-4 bg-white"
										>
											Log In
										</button>

										<hr />
									</form>

									<div className="login-mobile-sign">
										<p className="ls-024 fs-14 font-400 font-grey-light-4">
											Don't have an account?{" "}
											<a
												href="#"
												onClick={() => history.push("/sign-up")}
												className="font-500 font-dark-blue"
											>
												Sign up
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{isLoading && <SpinnerLoader />}
			</div >
		</>
	);
};

export default withRouter(Login);
