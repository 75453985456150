import Drawer from "@material-ui/core/Drawer";
import { useFormik, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import get from "lodash/get";
import { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { SpinnerLoader } from "../../common/Global.Style";
import { toast } from "react-toastify";
import { RemoveCircle } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { uploadFile } from "../../api/TestController";

interface IAddProductProps {
	open: boolean;
	onclose: () => void;
	onSUbmit: (values: any) => void;
	formData: any;
	setIsLoading: (values: any) => void;
	isLoading: boolean;
}

export const AddEditAnalytes = ({
	open,
	onclose,
	formData,
	onSUbmit,
	setIsLoading,
	isLoading,
}: IAddProductProps) => {
	let validationSchema: any = {
		name: Yup.string().trim().required("Name is required"),
		code: Yup.string().trim().required("Code is required"),
		good: Yup.object().shape({
			min: Yup.number()
				.required('Min value is required'),
			max: Yup.number()
				.required('Max value is required')
				.when('min', (min, schema) =>
					min
						? schema.moreThan(
							min,
							'Max value must be greater than the min value'
						)
						: schema
				),
		}),
		moderate: Yup.object().shape({
			min: Yup.number()
				.required('Min value is required'),
			max: Yup.number()
				.required('Max value is required')
				.when('min', (min, schema) =>
					min
						? schema.moreThan(
							min,
							'Max value must be greater than the min value'
						)
						: schema
				),
		}),
		poor: Yup.object().shape({
			min: Yup.number()
				.required('Min value is required'),
			max: Yup.number()
				.required('Max value is required')
				.when('min', (min, schema) =>
					min
						? schema.moreThan(
							min,
							'Max value must be greater than the min value'
						)
						: schema
				),
		}),
	};

	const defaultSlide = {
		_id: "",
		url: "",
		type: "image",
	}

	const onChooseFile = async (event: any, status: string, index: number) => {
		const apiBody = new FormData();
		const [file] = event.target.files;
		apiBody.append("file", file);
		apiBody.append("host", `${process.env.REACT_APP_API_BASE_URL}/media`);
		apiBody.append("folder", "analytes");
		setIsLoading(true);
		const response = await uploadFile(apiBody);
		setIsLoading(false);
		if (response?.error) {
			toast.error(response?.error);
		} else if (response?.responseObj?.data) {
			const field = `${status}.media.${index}`;
			const value = {
				url: response?.responseObj?.data?.url,
				type: response?.responseObj?.data?.type,
				_id: response?.responseObj?.data?._id
			}
			formik.setFieldValue(field, value);
		}
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			code: "",
			definition: "",
			good: {
				min: 0,
				max: 0,
				media: [defaultSlide],
			},
			moderate: {
				min: 0,
				max: 0,
				media: [defaultSlide],
			},
			poor: {
				min: 0,
				max: 0,
				media: [defaultSlide],
			},
		},
		validationSchema: Yup.object().shape(validationSchema),
		onSubmit: ({
			name,
			code,
			definition,
			good,
			moderate,
			poor,
		}) => {
			onSUbmit(
				{
					name: name.trim(),
					code: code.trim(),
					definition: definition.trim(),
					good: { ...good, media: good.media.map((m: any) => m._id) },
					moderate: { ...moderate, media: moderate.media.map((m: any) => m._id) },
					poor: { ...poor, media: poor.media.map((m: any) => m._id) },
				}
			);
		},
	});

	useEffect(() => {
		if (!isEmpty(formData)) {
			formik.setFieldValue("definition", get(formData, "definition"));
			formik.setFieldValue("name", get(formData, "name"));
			formik.setFieldValue("code", get(formData, "code"));
			formik.setFieldValue("good.min", get(formData, "good.min"));
			formik.setFieldValue("good.max", get(formData, "good.max"));
			formik.setFieldValue("good.media", get(formData, "good.media"));
			formik.setFieldValue("moderate.min", get(formData, "moderate.min"));
			formik.setFieldValue("moderate.max", get(formData, "moderate.max"));
			formik.setFieldValue("moderate.media", get(formData, "moderate.media"));
			formik.setFieldValue("poor.min", get(formData, "poor.min"));
			formik.setFieldValue("poor.max", get(formData, "poor.max"));
			formik.setFieldValue("poor.media", get(formData, "poor.media"));
		}
	}, []);

	return (
		<>
			<Drawer anchor={"right"} open={open} onClose={onclose}>
				{isLoading && <SpinnerLoader />}
				<div className="d-flex justify-content-between p-3">
					<p className="align-items-center d-flex mb-0">
						{`${isEmpty(formData) ? "Add" : "Edit"} Analytes`}
					</p>
					<IconButton onClick={onclose}>
						<CloseIcon />
					</IconButton>
				</div>

				<FormikProvider value={formik}>
					<form className="p-4" onSubmit={formik.handleSubmit}>
						<div className="mb-3 form-group">
							<label className="email">Name</label>
							<input
								type="text"
								id="name"
								name="name"
								className="bg-light border-0 mt-2"
								placeholder="Enter Name"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.name}
							/>
							{formik.touched.name && formik.errors.name ? (
								<div className="text-danger">{formik.errors.name}</div>
							) : null}
						</div>

						<div className="mb-3 form-group">
							<label className="email">Code</label>
							<input
								type="text"
								id="code"
								name="code"
								className="bg-light border-0 mt-2"
								placeholder="Enter Code"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.code}
							/>
							{formik.touched.code && formik.errors.code ? (
								<div className="text-danger">{formik.errors.code}</div>
							) : null}
						</div>

						<div className="mb-3 form-group">
							<label className="email">Definition</label>
							<textarea
								name="definition"
								id="definition"
								value={formik.values.definition}
								onChange={(e: any) => formik.setFieldValue("definition", e?.target?.value)}
							/>
							{formik.touched.definition && formik.errors.definition ? (
								<div className="text-danger">{formik.errors.definition}</div>
							) : null}
						</div>

						<div className="mb-3">
							<h6 className=" mb-2">Good</h6>
							<div className="mb-3 form-group d-flex">
								<label className="mt-3">Min</label>
								<input
									type="number"
									id="good-min"
									name="good.min"
									className="mt-2 w-50 ml-3 mr-3"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.good.min}
								/>
								<label className="mt-3">Max</label>
								<input
									type="number"
									id="good-max"
									name="good.max"
									className="mt-2 w-50 ml-3 mr-3"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.good.max}
								/>
							</div>
							{formik.touched.good?.min && formik.errors.good?.min && formik.isSubmitting ? (
								<div className="text-danger">{formik.errors.good?.min}</div>
							) : null}

							{formik.touched.good?.max && formik.errors.good?.max ? (
								<div className="text-danger">{formik.errors.good?.max}</div>
							) : null}

							<FieldArray
								name="good.media"
								render={(arrayHelpers) => (
									<>
										{formik.values.good.media?.map((item: any, index: number) => (
											<>
												<div className="mb-3 form-group">
													<div className="d-flex">
														<label className="mt-3 mr-3">{`Slide - ${index + 1}`}</label>
														<input
															accept="image/*, video/*"
															style={{ display: "none" }}
															id={`good-${index}`}
															type="file"
															onClick={(event: any) => {
																event.target.value = null;
															}}
															onChange={(event: any) => onChooseFile(event, "good", index)}
														/>
														{(item?.type === "image" && item.url?.length !== 0) ?
															(<img className="media mr-4" src={item.url} alt="" />)
															: (<video className="media mr-4" src={item.url} />)
														}

														<div className="text-right mr-4">
															<label htmlFor={`good-${index}`}>
																<p className="btn add-device-btn upload-btn cursor-pointer m-0">
																	Upload
																</p>
															</label>
														</div>

														{
															formik.values.good.media.length > 1 && (
																<div className="align-items-center d-flex justify-content-center">
																	<RemoveCircle
																		onClick={() => {
																			arrayHelpers.remove(index)
																		}
																		}
																		className="txt-blue cursor-pointer remove-icon"
																	/>
																</div>
															)
														}
													</div>
												</div>
											</>
										))}

										<div className="text-right plus-icon">
											<span className="mt-2 cursor-pointer rounded-circle p-3">
												<AddIcon
													onClick={() => {
														arrayHelpers.push({
															url: "",
															type: "image",
														})
													}
													}
												/>
											</span>
										</div>
									</>
								)}
							/>
						</div>

						<div className="mb-3">
							<h6 className=" mb-2">Moderate</h6>
							<div className="mb-3 form-group d-flex">
								<label className="mt-3">Min</label>
								<input
									type="number"
									id="moderate-min"
									name="moderate.min"
									className="mt-2 w-50 ml-3 mr-3"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.moderate.min}
								/>
								<label className="mt-3">Max</label>
								<input
									type="number"
									id="moderate-max"
									name="moderate.max"
									className="mt-2 w-50 ml-3 mr-3"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.moderate.max}
								/>
							</div>
							{formik.touched.moderate?.min && formik.errors.moderate?.min ? (
								<div className="text-danger">{formik.errors.moderate?.min}</div>
							) : null}

							{formik.touched.moderate?.max && formik.errors.moderate?.max ? (
								<div className="text-danger">{formik.errors.moderate?.max}</div>
							) : null}


							<FieldArray
								name="moderate.media"
								render={(arrayHelpers) => (
									<>
										{formik.values.moderate.media?.map((item: any, index: number) => (
											<>
												<div className="mb-3 form-group">
													<div className="d-flex">
														<label className="mt-3 mr-3">{`Slide - ${index + 1}`}</label>
														<input
															accept="image/*, video/*"
															style={{ display: "none" }}
															id={`moderate-${index}`}
															type="file"
															onClick={(event: any) => {
																event.target.value = null;
															}}
															onChange={(event: any) => onChooseFile(event, "moderate", index)}
														/>
														{(item?.type === "image" && item.url?.length !== 0) ?
															(<img className="media mr-3" src={item.url} alt="" />)
															: (<video className="media mr-4" src={item.url} />)
														}

														<div className="text-right mr-4">
															<label htmlFor={`moderate-${index}`}>
																<p className="btn add-device-btn upload-btn cursor-pointer m-0">
																	Upload
																</p>
															</label>
														</div>
														<div className="w-50">
														</div>
														{
															formik.values.good.media.length > 1 && (
																<div className="align-items-center d-flex justify-content-center">
																	<RemoveCircle
																		onClick={() => {
																			arrayHelpers.remove(index)
																		}
																		}
																		className="txt-blue cursor-pointer remove-icon"
																	/>
																</div>
															)
														}
													</div>
												</div>
											</>
										))}

										<div className="text-right plus-icon">
											<span className="mt-2 cursor-pointer rounded-circle p-3">
												<AddIcon
													onClick={() => {
														arrayHelpers.push({
															url: "",
															type: "image",
														})
													}
													}
												/>
											</span>
										</div>
									</>
								)}
							/>
						</div>

						<div className="mb-3">
							<h6 className=" mb-2">Poor</h6>
							<div className="mb-3 form-group d-flex">
								<label className="mt-3">Min</label>
								<input
									type="number"
									id="poor-min"
									name="poor.min"
									className="mt-2 w-50 ml-3 mr-3"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.poor.min}
								/>
								<label className="mt-3">Max</label>
								<input
									type="number"
									id="poor-max"
									name="poor.max"
									className="mt-2 w-50 ml-3 mr-3"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.poor.max}
								/>
							</div>
							{formik.touched.poor?.min && formik.errors.poor?.min ? (
								<div className="text-danger">{formik.errors.poor?.min}</div>
							) : null}

							{formik.touched.poor?.max && formik.errors.poor?.max ? (
								<div className="text-danger">{formik.errors.poor?.max}</div>
							) : null}


							<FieldArray
								name="poor.media"
								render={(arrayHelpers) => (
									<>
										{formik.values.poor.media?.map((item: any, index: number) => (
											<>
												<div className="mb-3 form-group">
													<div className="d-flex">
														<label className="mt-3 mr-3">{`Slide - ${index + 1}`}</label>
														<input
															accept="image/*, video/*"
															style={{ display: "none" }}
															id={`poor-${index}`}
															type="file"
															onClick={(event: any) => {
																event.target.value = null;
															}}
															onChange={(event: any) => onChooseFile(event, "poor", index)}
														/>
														{(item?.type === "image" && item.url?.length !== 0) ?
															(<img className="media mr-3" src={item.url} alt="" />)
															: (<video className="media mr-3" src={item.url} />)
														}

														<div className="text-right mr-4">
															<label htmlFor={`poor-${index}`}>
																<p className="btn add-device-btn upload-btn cursor-pointer m-0">
																	Upload
																</p>
															</label>
														</div>
														<div className="w-50">
														</div>
														{
															formik.values.poor.media.length > 1 && (
																<div className="align-items-center d-flex justify-content-center">
																	<RemoveCircle
																		onClick={() => {
																			arrayHelpers.remove(index)
																		}
																		}
																		className="txt-blue cursor-pointer remove-icon"
																	/>
																</div>
															)
														}
													</div>
												</div>
											</>
										))}

										<div className="text-right plus-icon">
											<span className="mt-2 cursor-pointer rounded-circle p-3">
												<AddIcon
													onClick={() => {
														arrayHelpers.push({
															url: "",
															type: "image",
														})
													}
													}
												/>
											</span>
										</div>
									</>
								)}
							/>
						</div>

						<button type="submit" className="btn w-100 mb-3 btn-medium">
							{!isEmpty(formData) ? "Update" : "Add"}
						</button>
					</form>
				</FormikProvider>
			</Drawer>
		</>

	);
};
