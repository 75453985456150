import { ResetPasswordModal } from "../pages/ResetPassword/ResetPassword";
import apiPlugin, { ApiResponse } from "./apiPlugin";

export async function loginApi(userDetail: Object): Promise<ApiResponse<any>> {
  return apiPlugin<any>("PUT", "auth/login", undefined, userDetail);
}

export async function forgotPasswordApi(
  email: Object
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "auth/forgot-password", undefined, email);
}

export async function resetPasswordApi(
  resetPassword: ResetPasswordModal
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "auth/reset-password", undefined, resetPassword);
}

export async function getProfileDetails(): Promise<ApiResponse<any>> {
  return apiPlugin<any>("GET", "user/profile");
}

export async function updateProfile(
  userDetail: Object
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("PUT", "user/profile", undefined, userDetail);
}

export async function getSignatureUrl(
  imageDetails: object
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "get-upload-url", undefined, imageDetails);
}

export async function changePassword(
  reqMap: object
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("PUT", `user/change-password`, undefined, reqMap);
}

export async function getResetPasswordLink(
  reqMap: object
): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/password-reset-link", undefined, reqMap);
}

export async function createUser(reqMap: object): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "auth/register", undefined, reqMap);
}

export async function uploadProfileImage(imageDetails: any): Promise<ApiResponse<any>> {
  return apiPlugin<any>("POST", "filter-testing/profile-upload", undefined, undefined, imageDetails);
}

