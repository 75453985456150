import Drawer from "@material-ui/core/Drawer";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { isNumber, isEmpty, cloneDeep } from "lodash";
import { SpinnerLoader } from "../../common/Global.Style";
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";

interface IAddEditLabTestProps {
  open: boolean;
  onclose: () => void;
  onSubmit: (values: any) => void;
  formData: any;
  isLoading: boolean;
  analytes: any[]
}

export const AddEditLabTest = ({
  open,
  onclose,
  formData,
  onSubmit,
  isLoading,
  analytes
}: IAddEditLabTestProps) => {
  let validationSchema: any = {
    firstName: Yup.string().trim().required("First Name is required"),
  };
  const [readings, setReadings] = useState<any>([]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      testId: "",
      labId: "",
      partnerId: "",
      address: "",
    },
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: ({
      firstName,
      lastName,
      email,
      testId,
      labId,
      partnerId,
      address,
    }) => {
      if (!readings.length) {
        toast.error('Please Import valid file');
        return;
      }
      onSubmit(
        {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email,
          testId,
          labId,
          partnerId,
          readings,
          address,
        }
      );
    },
  });

  useEffect(() => {
    if (!isEmpty(formData)) {
      formik.setFieldValue("firstName", get(formData, "firstName"));
      formik.setFieldValue("lastName", get(formData, "lastName"));
      formik.setFieldValue("email", get(formData, "email"));
      formik.setFieldValue("address", get(formData, "address"));
      formik.setFieldValue("testId", get(formData, "testId"));
      formik.setFieldValue("partnerId", get(formData, "partnerId"));
      formik.setFieldValue("labId", get(formData, "labId"));
      setReadings(formData.readings);
    }
  }, []);

  const parseFile = async (file: any) => {
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    let sheetName = "";
    const worksheet = sheetName.length !== 0 ? workbook.Sheets[sheetName] : workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
      blankrows: false
    });
    let rows: any[] = [];
    const userDetails: any = {};
    const userDetailsKey = [
      "firstname",
      "lastname",
      "email",
      "address",
      "testid",
      "labid",
      "testid",
      "partnerid"
    ];
    if (jsonData.length) {
      jsonData.forEach((data: any) => {
        const row = data.filter((d: any) => !isEmpty(d) || isNumber(d));
        const key = row[0].replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        rows.push({
          code: key,
          count: row[1],
        });
      });

      let parsedReadings = rows.map((row: any) => {
        const { _id, code } = analytes.find((a: any) => a.code.toLowerCase() === row.code.toLowerCase()) || {};
        return {
          count: row.count,
          analyteId: _id,
          code
        };
      });
      parsedReadings = parsedReadings.filter(p => p.analyteId);

      const filteredRow = rows.filter(r => userDetailsKey.includes(r.code));
      filteredRow.forEach(f => {
        userDetails[f.code] = f.count;
      });

      if (!isEmpty(userDetails)) {
        formik.setFieldValue("firstName", userDetails?.firstname);
        formik.setFieldValue("lastName", userDetails?.lastname);
        formik.setFieldValue("email", userDetails?.email);
        formik.setFieldValue("address", userDetails?.address);
        formik.setFieldValue("testId", userDetails?.testid);
        formik.setFieldValue("labId", userDetails?.labid);
        formik.setFieldValue("partnerId", userDetails?.partnerid);
      }

      if (parsedReadings.length) {
        setReadings(parsedReadings);
        toast.success("Readings Imported Successfully");
      } else {
        toast.error("Please Import valid File");
      }
    } else {
      toast.error("Please Import valid File");
    }
  }

  const onChangeReading = (value: number, index: number) => {
    let tempReadings = cloneDeep(readings);
    tempReadings[index].count = value;
    setReadings(tempReadings);
  }

  return (
    <>
      <Drawer anchor={"right"} open={open} onClose={onclose}>
        {isLoading && <SpinnerLoader />}
        <div className="d-flex justify-content-between p-3">
          <p className="align-items-center d-flex mb-0">
            {`${isEmpty(formData) ? "Add" : "Edit"} Test`}
          </p>
          <IconButton onClick={onclose}>
            <CloseIcon />
          </IconButton>
        </div>

        <FormikProvider value={formik}>
          <form className="p-4" onSubmit={formik.handleSubmit}>
            <div className="mb-3 bg-white org-wrap">
              <input
                accept=".csv, .xlsx"
                style={{ display: "none" }}
                id="images"
                type="file"
                onClick={(event: any) => {
                  event.target.value = null;
                }}
                onChange={(event: any) => {
                  parseFile(event?.target?.files[0]);
                }}
              />
              <div className="d-flex">
                <label htmlFor="images">
                  <p className="btn add-device-btn btn-medium btn-primary-outline upload-btn cursor-pointer m-0">
                    Import File
                  </p>
                </label>
              </div>
              <p className="desc-large">Import Readings from Excel/CSV File</p>
            </div>

            <div className="mb-3 form-group">
              <label className="email">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="bg-light border-0 mt-2"
                placeholder="Enter First Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="text-danger">{formik.errors.firstName}</div>
              ) : null}
            </div>

            <div className="mb-3 form-group">
              <label className="email">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className="bg-light border-0 mt-2"
                placeholder="Enter Last Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="text-danger">{formik.errors.lastName}</div>
              ) : null}
            </div>

            <div className="mb-3 form-group">
              <label className="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="bg-light border-0 mt-2"
                placeholder="Enter Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="mb-3 form-group">
              <label className="email">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                className="bg-light border-0 mt-2"
                placeholder="Enter Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              />
              {formik.touched.address && formik.errors.address ? (
                <div className="text-danger">{formik.errors.address}</div>
              ) : null}
            </div>

            <div className="mb-3 form-group">
              <label className="email">Test ID</label>
              <input
                type="text"
                id="testId"
                name="testId"
                className="bg-light border-0 mt-2"
                placeholder="Enter Test ID"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.testId}
              />
              {formik.touched.testId && formik.errors.testId ? (
                <div className="text-danger">{formik.errors.testId}</div>
              ) : null}
            </div>

            <div className="mb-3 form-group">
              <label className="email">Lab ID</label>
              <input
                type="text"
                id="labId"
                name="labId"
                className="bg-light border-0 mt-2"
                placeholder="Enter Lab ID"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.labId}
              />
              {formik.touched.labId && formik.errors.labId ? (
                <div className="text-danger">{formik.errors.labId}</div>
              ) : null}
            </div>

            <div className="mb-3 form-group">
              <label className="email">Partner ID</label>
              <input
                type="text"
                id="partnerId"
                name="partnerId"
                className="bg-light border-0 mt-2"
                placeholder="Enter Partner ID"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.partnerId}
              />
              {formik.touched.partnerId && formik.errors.partnerId ? (
                <div className="text-danger">{formik.errors.partnerId}</div>
              ) : null}
            </div>

            {readings.length !== 0 &&
              readings.map((item: any, index: number) => {
                return (
                  <div className="mb-3 form-group d-flex">
                    <p className="w-50">{item.code}</p>
                    <input
                      type="number"
                      id="count"
                      name="count"
                      className="mt-2 w-50 ml-3 mr-3"
                      value={item.count}
                      onChange={(e: any) => onChangeReading(e.target.value, index)}
                    />
                  </div>
                )
              })
            }

            <button type="submit" className="btn w-100 mb-3 btn-medium">
              {!isEmpty(formData) ? "Update" : "Generate"}
            </button>
          </form>
        </FormikProvider>
      </Drawer>
    </>
  );
};
