import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { getProfileDetails, updateProfile } from "../../api/UserController";
import { SpinnerLoader } from "../../common/Global.Style";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { useHistory } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { uploadFile } from "../../api/TestController";

const ReportSettings = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const history = useHistory();

	useEffect(() => {
		getUserProfile();
	}, [])

	const updateUserProfile = async (values: any) => {
		setIsLoading(true);
		const response = await updateProfile(values);
		setIsLoading(false);
		if (response.error) {
			toast.error(response.error);
		} else {
			toast.success(response.responseObj.message);
		}
	};

	const getUserProfile = async () => {
		setIsLoading(true);
		const response = await getProfileDetails();
		setIsLoading(false);
		if (response.responseObj.data) {
			const settings = response.responseObj.data.reportSettings;
			if (!isEmpty(settings)) {
				formik.setFieldValue("intro", get(settings, "intro"));
				formik.setFieldValue("outro", get(settings, "outro"));
				formik.setFieldValue("logo", get(settings, "logo"));
				formik.setFieldValue("emailContent", get(settings, "emailContent"));
			}
		}
	};

	const formik = useFormik({
		initialValues: {
			emailContent: "",
			logo: "",
			intro: {
				url: "",
				_id: "",
				type: ""
			},
			outro: {
				url: "",
				_id: "",
				type: ""
			}
		},
		validationSchema: Yup.object({
			emailContent: Yup.string()
				.trim()
				.required("Email Content is required"),
		}),
		onSubmit: (values) => {
			const reportSettings = {
				logo: values.logo,
				intro: values.intro?._id,
				outro: values.outro?._id,
				emailContent: values.emailContent
			}
			updateUserProfile({ reportSettings });
		},
	});

	const onChooseFile = async (event: any, field: string) => {
		const apiBody = new FormData();
		let [file] = event.target.files;
		apiBody.append("file", file);
		apiBody.append("host", `${process.env.REACT_APP_API_BASE_URL}/media`);
		apiBody.append("folder", "walkthru-labs");
		setIsLoading(true);
		const response = await uploadFile(apiBody);
		setIsLoading(false);
		if (response.responseObj.data) {
			const data = response.responseObj.data;
			if (field === "logo") {
				formik.setFieldValue("logo", data.url);
			} else {
				formik.setFieldValue(field, { url: data.url, type: data.type, _id: data._id });
			}
		} else if (response.error) {
			toast.error(response.error);
		}
	}

	return (
		<div className="container-fluid change-password-block">
			<div
				className="d-flex cursor-pointer mb-3"
				onClick={() => history.goBack()}
			>
				<ArrowBackIosIcon fontSize="small" className="ml-2" />
				<p className="breadcrumb-para">Back</p>
			</div>
			<div className="row">
				<div className="col-md-12 col-lg-10 col-sm-12">
					<div className="row mt-3 pl-3 pr-3 pt-3 h-100 pb-5 mb-2">
						<div className="col-md-12 col-lg-12 col-sm-12 profile-component bg-white p-4">
							<div className="mb-3">
								<h5>Walkthru Settings</h5>
							</div>
							<form onSubmit={formik.handleSubmit}>
								<div className="col-md-6 pb-4">
									<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">Email Content</label>
									<input
										id="emailContent"
										type="text"
										className="w-100  p-3 bg-white profileinput txt-black fs-14"
										placeholder="Email Content"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.emailContent}
									/>
									{formik.touched.emailContent && formik.errors.emailContent ? (
										<div className="text-danger">
											{formik.errors.emailContent}
										</div>
									) : null}
								</div>

								<div className="col-md-6 pb-4">
									<input
										accept="image/*"
										style={{ display: "none" }}
										id="logo"
										type="file"
										onClick={(event: any) => {
											event.target.value = null;
										}}
										onChange={(event: any) => {
											onChooseFile(event, "logo");
										}}
									/>
									<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">Logo</label>
									<div className="d-flex">
										{formik.values.logo?.length !== 0 &&
											<img className="media mr-4" src={formik.values.logo} />
										}
										<label htmlFor="logo">
											<p className="btn add-device-btn btn-medium btn-primary-outline upload-btn cursor-pointer m-0">
												Upload
											</p>
										</label>
									</div>

								</div>

								<div className="col-md-6 pb-4">
									<input
										accept="image/*, video/*"
										style={{ display: "none" }}
										id="intro"
										type="file"
										onClick={(event: any) => {
											event.target.value = null;
										}}
										onChange={(event: any) => {
											onChooseFile(event, "intro");
										}}
									/>
									<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">Intro</label>
									<div className="d-flex">
										{
											formik.values.intro?.url?.length !== 0 &&
											<div>
												{(formik.values.intro?.type === "image") ?
													(<img className="media mr-4" src={formik.values.intro?.url} />)
													: (<video className="media mr-4" src={formik.values.intro?.url} />)
												}
											</div>
										}

										<label htmlFor="intro">
											<p className="btn add-device-btn btn-medium btn-primary-outline upload-btn cursor-pointer m-0">
												Upload
											</p>
										</label>
									</div>

								</div>

								<div className="col-md-6 pb-4">
									<input
										accept="image/*, video/*"
										style={{ display: "none" }}
										id="outro"
										type="file"
										onClick={(event: any) => {
											event.target.value = null;
										}}
										onChange={(event: any) => {
											onChooseFile(event, "outro");
										}}
									/>
									<label className="font-400 fs-14 m-0 pb-2 lh-16 ls-024 font-grey-light-4">Outro</label>
									<div className="d-flex">
										{
											formik.values.outro?.url?.length !== 0 &&
											<div>
												{(formik.values.outro?.type === "image") ?
													(<img className="media mr-4" src={formik.values.outro?.url} />)
													: (<video className="media mr-4" src={formik.values.outro?.url} />)
												}
											</div>
										}
										<label htmlFor="outro">
											<p className="btn add-device-btn btn-medium btn-primary-outline upload-btn cursor-pointer m-0">
												Upload
											</p>
										</label>
									</div>
								</div>

								<div className="col-md-12 col-lg-6 col-sm-12">
									<div className="d-flex justify-content-center mt-1">
										<button type="submit" className="btn btn-submit">
											Save Settings
										</button>
									</div>
								</div>
							</form>
							{isLoading && <SpinnerLoader />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReportSettings;
