import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Redirect, Route, Switch } from "react-router-dom";

import Header from "../../components/Header/Header";
import { URL } from "../../components/Navbar/routes";
import Profile from "../Profile/Profile";

import "./Routes.scoped.scss";
import AnalytesList from "../Library/List";
import ReportSettings from "../../components/ReportSettings/ReportSettings";
import LabTest from "../LabTest/LabTest";

const Routes: React.FC = () => {

  return (
    <div>
      <div className="left-panel m-d-none">
        <Navbar />
      </div>
      <div className="right-panel">
        <Header />
        <div className="mobile-app-content">
          <Switch>
            <Route
              path={URL.DASHBOARD}
              exact
              component={LabTest}
            />
            <Route
              path={URL.LIBRARY}
              exact
              component={AnalytesList}
            />
            <Route
              path={URL.DASHBOARD_PROFILE}
              exact
              component={Profile}
            />
            <Route
              path={URL.REPORT_SETTINGS}
              exact
              component={ReportSettings}
            />
            <Route path="*">
              <Redirect to={URL.LOGIN} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Routes;
