import React, { useState } from "react";
import { IMenuItem } from "../../common/Interface";
import SubMenu from "./SubMenu";
import { URL } from "./routes";
import { SidebarNav, SidebarWrap } from "./Navbar.Style";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DashboardIcon from '@mui/icons-material/Dashboard';

import "./Navbar.scoped.scss";


const Sidebar: React.FC = () => {
  const logo = "https://icloud-image-uploader.s3.amazonaws.com/walkthru-labs/labs-logo.png";
  const [routes, setRoutes] = useState<any[]>([
    {
      title: "",
      icon: <DashboardIcon className="navbar-menu-icon" />,
      path: URL.DASHBOARD,
    },
    {
      title: "",
      icon: <LibraryBooksIcon className="navbar-menu-icon" />,
      path: URL.LIBRARY,
    },
  ])

  return (
    <SidebarNav>
      <SidebarWrap>
        <div className="mt-4 mb-5 text-center sidenav">
          <img className="text-white-color" src={logo} alt="" />
        </div>
        {
          routes.map((item: IMenuItem, index) => (
            <div className="mb-2" key={`${index}-${item.path}`}>
              <SubMenu item={item} keyValue={index} />
            </div>
          ))}
      </SidebarWrap>
    </SidebarNav>
  );
};

export default Sidebar;
