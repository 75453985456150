export const URL = {
  SIGN_UP: "/sign-up",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  LIBRARY: "/dashboard/library",
  DASHBOARD_PROFILE: "/dashboard/profile",
  REPORT_SETTINGS: "/dashboard/settings",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password"
};
